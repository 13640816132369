import React from "react";
import data from "../../hiringSteps.json";

import "./HiringProcess.css";

const HiringProcess = () => {
  return (
    <>
      <div className="our-hiring-process-container">
        <div className="flexStart hiring-title-container">
          <h2 className="title">Our Hiring Process</h2>
        </div>
        <div className="row h-container">
          <div className="col-md-6 hiring-process-left-section">
            {/* left section */}
            <div className="hiring-image-container">
              <img className="image" src="./hiring-process.png" alt="" />
            </div>
          </div>
          <div className="col-md-6 flexColEnd paddings right-section">
            {/* right section */}
            {/* <div className="flexColEnd paddings right-section"> */}
            {data.map((step) => {
              return (
                <div key={step.id} className="container">
                  <div className="row">
                    <div className="col-2 my-4  id-section">
                      <div className="">
                        <hr className="hrrr" />
                      </div>
                      <div className="idd">{step.id}</div>
                    </div>
                    <div className="col">
                      <div
                        className="card w-60 right-section"
                        style={{ background: "#fffaf6", border: "0px" }}
                      >
                        <div className="card-body">
                          <h5 className="card-title c-title">{step.title}</h5>
                          <p className="card-text c-text">{step.discription}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default HiringProcess;
