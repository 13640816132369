import React from "react";
import { useNavigate } from "react-router-dom";
import "./Hero.css";

const Hero = () => {
  const navigate = useNavigate();

  function handleClick(event) {
    navigate("/contact-us", { state: "other" });
  }

  return (
    <>
      <div className="flexCenter paddings hero-wrapper">
        <div className="col-md-6 hero-left">
          {/* left section */}
          <div className="flexColStart">
            <div className="hero-title">
              {/* <div > */}
                 Build Dynamic Tech
                  Teams For Your 
                Business Needs              
              {/* </div> */}
            </div>
            <div className="mt-3 flexColStart hero-desc">
              {/* <span> */}
              We empower businesses and economies to scale with cutting-edge IT services.
              {/* </span> */}
            </div>

            <button className="nav-link contact-color c-padding" onClick={handleClick}>
              CONTACT US
            </button>
          </div>
        </div>
        <div className="col-md-6 hero-right">
          {/* right section */}
          <div className="hero-image-container">
            <img src="./hero.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
