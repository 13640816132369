import React from "react";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <div id="about" className="container about-container">
      <div className="about-us-title">About Us</div >
      <div className="about-us-text">
      NimbleByte Global is a comprehensive digital transformation leader. 
      We offer various professional services including Custom Software Solutions, Cloud Engineering,
       IT Consulting, and Staff Augmentation. We provide expert guidance and support from the initial stages 
       of inception and strategy to the design and implementation phases. Our commitment extends to continuous 
       analysis and assistance, ensuring that you remain in control of your digital future every step of the way.
      </div >
    </div>
  );
};

export default AboutUs;
