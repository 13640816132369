import React from "react";
import AboutUs from "../../components/AboutUs/AboutUs";
// import OurServices from "../../components/OurServices/OurServices";
import OurServicesNew from "../../components/OurServices/OurServicesNew";

import Hero from "../../components/Hero/Hero";
import Banner from "../../components/Banner/Banner";
import HiringProcess from "../../components/HiringProcess/HiringProcess";
import TechStack from "../../components/TechStack/TechStack";
import OurValuesCard from "../../components/OurValuesCards/OurValuesCard";
import Header from "../../components/Header/Header";

import "./Home.css";

const Home = () => {
  const navbar = {
    backgroundColor: "#27292B",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
  };
  const menuColor1 = {
    color: "#FA9147",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    padding: "20px 10px",
    gap: "8px",
  };

  const menuColor2 = {
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    padding: "20px 10px",
    gap: "8px",
  };
  const contactStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "12px 10px",
    gap: "8px",
    background: " #FFFFFF",
    // border: "1px solid #F97B22",
    borderRadius: "4px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#F97B22",
  };

  return (
    <>
      <div id="home" className="innerWidth home-wrapper">
        <Header
          logo="./nimble-logo2.png"
          logoAlt="NimbleByte"
          // style={{ backgroundColor: "#27292B" }}
          navbar={navbar}
          menuColor1={menuColor2}
          menuColor2={menuColor2}
          contactStyle={contactStyle}
        />

        {/* <div className="home-about-container"> */}
        <Hero />
        <AboutUs />
        {/* </div> */}
        <OurServicesNew />
        <OurValuesCard />
        <TechStack />
        <HiringProcess />
      </div>
      <Banner />

      {/* <a id="back-to-top" href="#" class="btn btn-light btn-lg back-to-top" role="button"><i class="fas fa-chevron-up"></i></a> */}
    </>
  );
};
export default Home;
