import data from "../../TechStack.json";
import "./TechStack.css"

const TechStack = () => {
  return (
    // <div className=" tech-stack-wrapper">
    <div
      className="innerWidth tech-stack-container"
    >
      <div className="flexStart py-1">
        <h2 className="tech-stack-title">Our Technology Stacks</h2>
      </div>
      <div className="flexCenter paddings tech-image-container">
        <div className="row">
          {data.TechStack_1.map((response) => (
            <div
              key={response.stackName}
              className=" col-sm-4 col-md-4 col-lg-2 my-4 flexColCenter"
            >
              <img
                key={response.stackName}
                className="pb-3 tech-stack-image"
                src={response.imageUrl}
                alt={response.stackName}
              />
              <div
                className="card text-white card-text"
                style={{
                  width: "10rem",
                  backgroundColor: "#4b4d4e",
                  borderRadius: "0px 8px 0px 8px",
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: "400",
                  lineHeight: "19px",
                  fontFamily: "Montserrat",
                }}
              >
                <div className="card-body">
                  <p className="card-text">{response.stackName}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default TechStack;
