import "./Footer.css";

const Footer = () => {
  return (
    <div className="f-wrapper">
      <div className="f-container">
        <div className="row footer-row">
          {/* left side */}
          <div className="col-md-4 paddings  l-column">
            {/* <div className=""> */}
            <span className="f-title">Nimble</span>
            <span className="f-title1">
              Byte Global<p></p>
            </span>
            {/* </div> */}

            <div className="sub-title">
              Partner with us today to revolutionize <br /> your company!
            </div>
            <div className="social">
            <a href="https://www.linkedin.com/company/nimblebyte-global" alt="linkedIn">
                <img
                  src="./linkedin.png"
                  alt="address"
                  className="address-img"
                />
              </a>

              <a href="#" alt="twitter">
                <img
                  src="./twitter.png"
                  alt="address"
                  className="address-img"
                />
              </a>
            </div>
            <br></br>
            <a href="mailto:information@nimble-byte.com" className="email-address">
              <img src="./email.png"
              alt="email"
              className="email-image">
              </img>
              <span>information@nimble-byte.com</span></a>  
          </div>
          {/* right side */}
          <div className="col-md-4 paddings  r-column">
            <div className="address-title">Office Address</div>

            <div className="address">
              <p>
                <img src="./house.png" alt="address" className="address1-img" />
              </p>
              <span className="">
                No 13, Maitama Sule Street,
                <br /> Off Raymond Njoku S/W,
                <br /> Ikoyi, Lagos <br />
                State. Nigeria.
                <br />
              </span>
            </div>
            <div className="phone">
              <img src="./call.png" alt="call" className="address1-img" />
              <span className="telephone"> +234-902-876-2111</span>
            </div>
          </div>
        </div>
        <div className="row footer-row">
          <hr className="hrr" />
          <div className=" flexStart footer-bottom">
            <div className="copyright-text">Copyright ©2024</div>
            <div className="company-name">
              <span className="footer-bottom-title">Nimble</span>
              <span className="footer-bottom-title1">Byte Global</span>
            </div>
            <div className="all-right">All Rights Reserved</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
