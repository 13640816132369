import { BrowserRouter, HashRouter, Routes, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Contact from './pages/ContactUs/Contact';
import Home from './pages/Home/Home'
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import AboutUs from "./components/AboutUs/AboutUs";
import ScrollToTop from "./components/ScrollButton/ScrollButton";

import './App.css';
import OurServices from "./components/OurServices/OurServices";


function App() {
  return (
<div className="main-container">
    <HashRouter basename="/" >
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
      />
      < ScrollToTop />

      <Routes>
        {/* <Route exact path="/home#about" element={<Home />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/our-service" element={<OurServices />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="*" element={<Home />} />

      </Routes>
      <Footer />
    </HashRouter>
    </div>
  );
}

export default App;
