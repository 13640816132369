import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Contact.css";
import ContactUs from "./ContactUs";
import ContactForm from "../../components/ContactForm/ContactForm";
import Header from "../../components/Header/Header";

const Contact = () => {
  const location = useLocation();

  const [contactUsType, setContactUsType] = useState("other");
  useEffect(() => {
    setContactUsType(location.state.contactType);
  }, [contactUsType]);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const navbar = {
    // backgroundColor: "#fffaf6",
    backgroundColor: "#fffaf6",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
  };
  // const menuColor1 = {
  //   color: "#4B4D4E",
  //   fontFamily: "Montserrat",
  //   fontSize: "16px",
  //   fontWeight: "700",
  //   lineHeight: "20px",
  //   letterSpacing: "0em",
  //   textAlign: "left",
  //   padding: "20px 16px",
  //   gap: "8px",
  // };

  const menuColor2 = {
    color: "#4B4D4E",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    padding: "20px 16px",
    gap: "8px",
  };
  const contactStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "12px 16px",
    gap: "8px",
    background: " #FFFFFF",
    border: "1px solid #F97B22",
    borderRadius: "4px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#F97B22",
  };

  return (
    <div id="contact" className="container-fluid c-wrapper">
      <div className="contact-navbar">
        <Header
          className="nav-styles"
          logo="./nimblelogo2.png"
          logoAlt="NimbleByte"
          navbar={navbar}
          menuColor1={menuColor2}
          menuColor2={menuColor2}
          contactStyle={contactStyle}
        />
      </div>
      <div className="paddings contact-us-container">
        {/* <div className="container"> */}
        <div className="row roww">
          {/* left side */}
          <div className="col-lg-6 c-left-column">
            <ContactUs
              contactTitle="Reach Out to Us to Assemble Your Team!"
              contactImg="./house.png"
              altHouse="House"
              address="No 13, Maitama Sule Street, Off Raymond Njoku S/W Ikoyi, Lagos
              "
              phone="./call.png"
              altPhn="Phone"
              phnNumber="+234-902-876-2111"
              logoImg1="./linkedin-with-bg.png"
              logoImg2="./twitter-with-bg.png"
              altLogo1="LinkedIn"
              altLogo2="Twitter"
            />
          </div>
          <div className="col-lg-6 c-right-column">
            <ContactForm helpType={contactUsType} />
          </div>
        </div>

        {/* <div className="contact-form-container"></div> */}
      </div>
    </div>
  );
};

export default Contact;
