import React from "react";
import OurValues from "../OurValues/OurValues.jsx";
import "./OurValuesCard.css";

import cardData from "../../../src/ourValues.json";

const OurValuesCard = () => {
  return (
    <div className="our-values-container" >
      <div className="flexStart values-title-container">
        <h2 className="title">Why Hiring Managers Prefer Us</h2>
      </div>

      <div className="row cards-wrapper">
        {cardData.map((card) => (
          <div key={card.id} className="mb-4 col-md-6 cards-container ">
            <OurValues
              key={card.id}
              imgSrc={card.imgSrc}
              imgAlt={card.imgAlt}
              title={card.title}
              description={card.description}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurValuesCard;
