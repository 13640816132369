import React from "react";

import "./OurServices.css";

const OurServices = () => {
  return (
    <>
      <div id="our-service" className="our-service-container">
        {/* <div className="flexStart paddings title-container">
          <h2 className="title">Our Services</h2>
        </div> */}
        <div className="row h-container">
          <div className="col-md-6 service-left-section">
            {/* left section */}
            <div className="service-image-container">
              <img className="service-image" src="./teamwork3.png" alt="" />
            </div>
          </div>
          <div className="col-md-6 flexColEnd paddings right-section">
            {/* right section */}
            <div className="flexColStart paddings service-title-container">
              <div className="service-title">
                <h1>What We Do</h1>
              </div>
              <div className="flexColStart">
                <span className="service-desc">
                  At NimbleByte Global,, what we do best is{" "}
                  <strong>Staff Augmentation.</strong>
                  <br />
                  Whether you need to strengthen your existing teams or require
                  specialized skills for a particular project phase, we bring
                  the right talent to complement your in-house capacity.
                  <p>
                    <br />
                    We also assemble dedicated development teams from our talent
                    pool to work exclusively for your organization, providing
                    high-quality software development and technical expertise.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OurServices;
