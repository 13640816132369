import React from "react";
import { Link } from "react-router-dom";
import "./ContactUs.css";

const ContactUs = ({
  contactTitle,
  contactImg,
  altHouse,
  address,
  phone,
  altPhn,
  phnNumber,
  logoImg1,
  logoImg2,
  altLogo1,
  altLogo2,
}) => {
  return (
    <div id="contact" className="contact-container">
      <div className="contact1">
        <h2 className="contact-us-title">{contactTitle}</h2>
      </div>
      <div className="address-description-container">
        <div className="house-icon-container">
          <p>
            <img src={contactImg} alt={altHouse} height={24} width={24} />
          </p>
        </div>
        <div className="address-container-layout">
          <p className="address-description">{address}</p>
        </div>
        <div className="phone-number-container">
          <div className="phone-icon-container">
            <p>
              <img src={phone} alt={altPhn} height={24} width={24} />
            </p>
          </div>
          <div className="phone-container-layout">
            <p className="phone-description">{phnNumber}</p>
          </div>

          <div className="logos-container">
            <div className="">
              <a href="#" alt="linkedin">
                <img src={logoImg1} alt={altLogo1} className="social-img" />
              </a>

              <a href="#" alt="twitter">
                <img src={logoImg2} alt={altLogo2} className="social-img" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
