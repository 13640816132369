import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

import "./Header.css";

const Header = ({
  logo,
  logoAlt,
  style,
  menuColor1,
  menuColor2,
  contactStyle,
  navbar,
}) => {
  const navigate = useNavigate();

  function handleClick(event) {
    navigate("/contact-us", { state: "other" });
  }

  return (
    // <div className="container-fluid">
    <nav
      className="navbar navbar-expand-lg  navbar-dark innerWidth fixed-top navbar-container"
      style={navbar}
    >
      <span className="navbar-brand d-flex w-50 me-auto">
        <nav
          className="nav pl-3 lead"
          // style={{ marginTop: "-3%" }}
        >
          <Link to={"/"}>
            <img src={logo} alt={logoAlt} height={60} width={60} />
          </Link>
        </nav>
      </span>

      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div
        className="collapse navbar-collapse justify-content-end"
        id="navbarNav"
      >
        <ul className="navbar-nav">
          <li>
            <NavHashLink
              className="nav-link nav-item"
              style={menuColor2}
              // style={{ color: "#FA9147" }}
              aria-current="page"
              // to="/"
              to="/#home"
            >
              HOME
            </NavHashLink>
          </li>
          <li>
            <NavHashLink
              className="nav-link nav-item"
              style={menuColor2}
              to="/#about"
            >
              ABOUT US
            </NavHashLink>
          </li>
          <li>
            <NavHashLink
              className="nav-link nav-item"
              style={menuColor2}
              to="/#our-service"
            >
              SERVICES
            </NavHashLink>
          </li>
          <li className="nav-item contact-us-style">
            {/* <NavHashLink className="nav-link" > */}
            <button
              className="nav-link nav-contact-color"
              style={contactStyle}
              onClick={handleClick}
            >
              CONTACT US
            </button>
            {/* </NavHashLink> */}
          </li>
        </ul>
      </div>
      {/* </div> */}
    </nav>
    // </div>
  );
};
export default Header;
