import React from "react";
import "./OurValues.css";

const OurValues = ({ imgSrc, imgAlt, title, description }) => {
  return (
    <div>
      <div className="card1">
        <div className="thumbnail-container">
          <img src={imgSrc} alt={imgAlt} className="card-thumbnail" />
        </div>
        <div className="our-value-title-container">
          <h2 className="card-title">{title}</h2>
        </div>
        <div>
          <p className="card-description">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default OurValues;
