import { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./Banner.css";

const Banner = () => {
  const navigate = useNavigate();
  const [contactType, useContactType] = useState("engineer");

  function handleClick(event) {
    navigate("/contact-us", { state: { contactType } });
  }

  return (
    <div className="b-wrapper">
      <div className=" flexCenter b-container">
        <div className="paddings text">
          Ready to work with the best hands to bring your projects to live?
        </div>
        <div className="hire-button">
          <button className="nav-link b-button" onClick={handleClick}>
          CONTACT US
          </button>
        </div>
      </div>
    </div>
  );
};
export default Banner;
